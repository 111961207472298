body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#blogo
{
    float: left;
}
#bphrase
{
    float: right;
}
.btn-primary:disabled 
{
  color: #ffffff !important;
    background-color: #898989 !important;
    border-color: #898989 !important;
}
.btn-primary
{
  color: #ffffff !important;
    background-color: #ee1100 !important;
    border-color: #ee1100 !important;
}
.email {
  color: #898989;
}
.colPRS {
  background-color: rgba(39,41,43,0.03);
  border: 1px solid rgba(39,41,43,0.1)
}
.tips {
    padding: 1.5rem;
}
